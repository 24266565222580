import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {connectRouter} from 'connected-react-router';
import {createBlacklistFilter} from 'redux-persist-transform-filter';
import {combineReducers} from 'redux';
import {admin, auth, sprint, messages, userSprints, workout, pod, dashboard, nff, devWorkout} from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    createBlacklistFilter('auth', [
      'isFetchingCode',
      'inProgress',
      'codeError',
    ]),
  ],
  whitelist: ['auth', 'dashboard'],
};

const createCombinedReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    admin,
    auth,
    sprint,
    workout,
    pod,
    nff,
    userSprints,
    // userTeamSprints,
    messages,
    dashboard,
    devWorkout,
  });

export default (history) =>
  persistReducer(persistConfig, createCombinedReducer(history));

import {createReducer} from '../utils';
import {ON_SECTION_CHANGE} from '../actions/types';

const initialState = {
  section: "sprints",
};

const dashboardReducer = createReducer(initialState, {
  [ON_SECTION_CHANGE]: (_, {section}) => ({
    section,
  }),
});

export default dashboardReducer;

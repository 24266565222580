import {put, call, takeLatest} from 'redux-saga/effects';
import {
  GET_USER_SPRINT,
  SET_USER_SPRINT,
  GET_USER_SPRINTS,
  SET_USER_SPRINTS,
  UPDATE_SPRINT,
  REQUEST_MAPS,
  CREATE_MAP,
  SET_MAPS,
  SAVE_TEXT_ENTRY,
  DELETE_TEXT_ENTRY,
  UPDATE_TEXT_ENTRY,
  SAVE_BIG_ASSUMPTION,
  DELETE_BIG_ASSUMPTION,
  SAVE_GOAL,
  SAVE_EXPERIMENT_ENTRY,
  UPDATE_EXPERIMENT_ENTRY,
  SET_EXPERIMENTS,
  UPDATE_BIG_ASSUMPTIONS,
  UPDATE_BIG_ASSUMPTION,
  UPDATE_BIG_ASSUMPTIONS_ORDER,
  UPDATE_TEXT_ENTRIES_ORDER,
  UPDATE_EXPERIMENTS_ORDER,
  UPDATE_TAKING_STOCK_QUESTION,
  SET_QUESTION_FOR_TAKING_STOCK,
  DELETE_TAKING_STOCK_QUESTION_ENTRY,
  TOGGLE_BIG_ASSUMPTION,
  UPDATE_TAKING_STOCK_RANGE_QUESTION,
  ON_MESSAGE,
  TOGGLE_BUSINESS_GOAL,
  SET_BUSINESS_GOAL_COLLAPSED,
  CREATE_EXPERIMENT,
  UPDATE_EXPERIMENTS,
  UPDATE_EXPERIMENT,
  // UPDATE_EXPERIMENT_IMPACT,
  SET_EXPERIMENT_IMPACT,
  TOGGLE_EXPERIMENT,
  SET_UPDATED_EXPERIMENT,
  DELETE_EXPERIMENT,
  DELETE_GOAL,
  DELETE_BUSINESS_GOAL,
  SET_EMPTY_GOAL,
  SET_EMPTY_BUSINESS_GOAL,
  
  DELETE_GOAL_NOTE,
  CREATE_GOAL_NOTE,
  UPDATE_GOAL_NOTE,
  UPDATE_GOAL_NOTES_ORDER,
  SET_GOAL_NOTES,
} from '../../actions/types';
import Api from '../../utils/api';
import {forwardTo} from "../../utils";
import {notify} from '../../utils/airbrake';

function getSprintApi(userSprintId) {
  return Api.get(`/user-sprint/${userSprintId}`);
}

function updateSprintApi(userSprintId, sharedValue) {
  return Api.post(`/user-sprint/${userSprintId}/${sharedValue}`);
}

function* onSuccess(message) {
  yield put({
    type: ON_MESSAGE,
    message: message,
    messageType: 'success',
  });
}

function* onError(error) {
  notify({
    error,
    context: { component: 'Sprint Sagas', severity: 'error' },
  });

  yield put({
    type: ON_MESSAGE,
    message: error,
    messageType: 'error',
  });
}

function getSprintsApi() {
  return Api.get('/user-sprint/all');
}

function requestMapsApi({description, id, mapsCount}) {
  return Api.post(`/user-sprint/request-maps/${id}`, {description, mapsCount});
}

function createMapApi({description, id, mapsCount}) {
  return Api.post(`/user-sprint/create-map/${id}`);
}

function saveTextEntryApi(params) {
  return Api.post('/text-entry', params);
}

function saveExperimentEntryApi(params) {
  return Api.post('/experiment/entry', params);
}

function updateExperimentEntryApi(params) {
  return Api.post(`/experiment/entry/${params.value._id}`, {
    bigAssumptionId: params.id,
    text: params.value.text,
  });
}

function deleteTextEntryApi(id) {
  return Api.delete(`/text-entry/${id}`);
}

function deleteBigAssumptionApi({id, goalId}) {
  return Api.delete(`/big-assumption/${id}`, {goalId});
}

function deleteGoalApi({goalId}) {
  return Api.delete(`/goal/${goalId}`);
}

function deleteBusinessGoalApi({goalId}) {
  return Api.delete(`/goal/business/${goalId}`);
}

function deleteExperimentApi({experimentId, id}) {
  return Api.delete(`/big-assumption/${id}/experiment`, {experimentId});
}

function updateGoalApi({goalId, field, value}) {
  return Api.post(`/goal/${goalId}`, {field, value});
}

// function updateGoalNoteApi({goalId, value}) {
//   return Api.post(`/goal/${goalId}/note`, {value});
// }
// function createGoalNoteApi({goalId, value}) {
//   return Api.post(`/goal/${goalId}/goal`, {value});
// }
// function deleteGoalNoteApi({goalId, value}) {
//   return Api.delete(`/goal/${goalId}/goal-note`, {value});
// }

function createGoalNoteApi({id, ...params}) {
  return Api.post(`/goal/${id}/note`, params);
}

function updateGoalNoteApi({id, entryId, ...params}) {
  return Api.post(`/goal/${id}/note/${entryId}`, params);
}

function deleteGoalNoteApi({id, entryId, ...params}) {
  return Api.delete(`/goal/${id}/note/${entryId}`, params);
}

function updateGoalNotesOrderApi({id, ...params}) {
  return Api.post(`/goal/${id}/notes/reorder`, params);
}

function createBigAssumptionApi({goalId, field, value}) {
  return Api.post('/big-assumption', {field, value, goalId});
}

function createNewExperimentApi(bigAssumptionId) {
  return Api.post(`/big-assumption/${bigAssumptionId}/experiments`);
}

function updateBigAssumptionApi({goalId, field, value}) {
  return Api.post(`/big-assumption/${value.bigAssumptionId}`, {
    field,
    value,
    goalId,
  });
}

function updateBigAssumptionsOrderInGoalApi({goalId, bigAssumptionIds}) {
  return Api.post(`/goal/${goalId}/big-assumptions/reorder`, {bigAssumptionIds});
}

function updateTextEntriesOrderInGoalApi({goalId, textEntriesIds, field}) {
  return Api.post(`/goal/${goalId}/text-entries/reorder`, {
    textEntriesIds,
    field,
  });
}

function updateExperimentsOrderApi({
  expId,
  bigAssumptionId,
  textEntriesIds,
  field,
}) {
  return Api.post(`/experiment/${expId}/entries/reorder`, {
    textEntriesIds,
    bigAssumptionId,
    field,
  });
}

function updateExperimentImpactApi({
  expId,
  bigAssumptionId,
  impact,
}) {
  return Api.post(`/experiment/${expId}/impact`, {
    impact,
    bigAssumptionId,
  });
}

function updateTakingStockQuestionApi({takingStockId, value}) {
  return Api.post(`/taking-stock/${takingStockId}`, {value});
}

function updateTakingStockRangeQuestionApi({takingStockId, field, value}) {
  return Api.post(`/taking-stock/${takingStockId}/update-range`, {
    field,
    value,
  });
}

function deleteTakingStockQuestionEntryApi({takingStockId, value}) {
  return Api.post(`/taking-stock/${takingStockId}/delete/entry`, {value});
}

function toggleBigAssumptionApi({assumptionId, isActive}) {
  return Api.post(`/big-assumption/toggle/${assumptionId}`, {isActive});
}

function toggleExperimentApi({_id, isHidden}) {
  return Api.post(`/big-assumption/experiment/toggle/${_id}`, {isHidden})
}

function toggleBusinessGoalApi({goalId, isBGCollapsed}) {
  return Api.post(`/goal/${goalId}/bg-toggle`, {isBGCollapsed})
}

function* getSprint(action) {
  // const {
  //   params: {userSprintId},
  // } = action;

  // const sprint = yield call(getSprintApi, userSprintId);

  // if (sprint && sprint.error) {
  //   return forwardTo({
  //     location: '/auth/forbidden',
  //     state: {
  //       condition: sprint.error,
  //       userId: sprint.userId,
  //     },
  //     needReplace: true
  //   });
  // }
  
  // yield put({type: SET_USER_SPRINT, sprint});
  try {
    const {
      params: {userSprintId},
    } = action;
    
    const sprint = yield call(getSprintApi, userSprintId);
    
    if (sprint && sprint.error) {
      return forwardTo({
        location: '/auth/forbidden',
        state: {
          condition: sprint.error,
          userId: sprint.userId,
        },
        needReplace: true
      });
    }

    yield put({type: SET_USER_SPRINT, sprint});
  } catch (error) {
    yield onError(error);
  }
}

function* updateSprint(action) {
  try {
    const {
      sprintId,
      sharedValue
    } = action;

    const sprints = yield call(updateSprintApi, sprintId, sharedValue);

    yield put({type: SET_USER_SPRINTS, sprints: sprints.map(s => ({...s, key: s._id}))});
  } catch (error) {
    yield onError(error);
  }
}

function* getSprints() {
  try {
    const sprints = yield call(getSprintsApi);

    yield put({type: SET_USER_SPRINTS, sprints: sprints.map(s => ({...s, key: s._id}))});
  } catch (error) {
    yield onError(error);
  }
}

function* requestMaps(action) {
  try {
    const {params} = action;
    const {maps, message} = yield call(requestMapsApi, params);

    yield put({type: SET_MAPS, maps, id: params.id});
    yield onSuccess(message);
  } catch (error) {
    yield onError(error);
  }
}

function* createMap(action) {
  try {
    const {params} = action;
    const {map, message} = yield call(createMapApi, params);

    yield put({type: SET_MAPS, maps: [map], id: params.id});
    yield onSuccess(message);
  } catch (error) {
    yield onError(error);
  }
}


function* saveTextEntry(action) {
  try {
    const {params} = action;
    const newEntries = yield call(saveTextEntryApi, params);

    yield put({type: UPDATE_TEXT_ENTRY, value: {[params.field]: newEntries}});
  } catch (error) {
    yield onError(error);
  }
}

function* saveGoal(action) {
  try {
    const {params} = action;
    const goal = yield call(updateGoalApi, {...params});
    yield put({type: UPDATE_TEXT_ENTRY, value: {label: goal.label}});
  } catch (error) {
    yield onError(error);
  }
}

function* updateGoalNote(action) {
  try {
    const {params} = action;
    const notes = yield call(updateGoalNoteApi, params);
    yield put({type: SET_GOAL_NOTES, notes});
  } catch(error) {
    yield onError(error);
  }
}
function* createGoalNotes(action) {
  try {
    const {params} = action;
    const notes = yield call(createGoalNoteApi, params);
    yield put({type: SET_GOAL_NOTES, notes});
  } catch(error) {
    yield onError(error);
  }
}
function* deleteGoalNotes(action) {
  try {
    const {params} = action;
    const notes = yield call(deleteGoalNoteApi, params);
    yield put({type: SET_GOAL_NOTES, notes});
  } catch(error) {
    yield onError(error);
  }
}

function* updateNotesOrder(action) {
  try {
    const {params} = action; 
    const notes = yield call(updateGoalNotesOrderApi, params);

    yield put({type: SET_GOAL_NOTES, notes});
  } catch (error) {
    yield onError(error);
  }
}

function* saveExperimentEntry(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(saveExperimentEntryApi, {...params});
    yield put({type: SET_EXPERIMENTS, bigAssumption});
  } catch (error) {
    yield onError(error);
  }
}

function* updateExperimentEntry(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(updateExperimentEntryApi, params);
    yield put({type: SET_EXPERIMENTS, bigAssumption});
  } catch (error) {
    yield onError(error);
  }
}

function* saveBigAssumption(action) {
  try {
    const {params} = action;
    let bigAssumptions;

    if (!params.value.bigAssumptionId) {
      bigAssumptions = yield call(createBigAssumptionApi, {...params});
    } else {
      bigAssumptions = yield call(updateBigAssumptionApi, {...params});
    }

    yield put({type: UPDATE_BIG_ASSUMPTIONS, bigAssumptions});
  } catch (error) {
    yield onError(error);
  }
}

function* createNewExperiments(action) {
  try {
    const {params} = action;
    
    const experiment = yield call(createNewExperimentApi, params);

    yield put({type: UPDATE_EXPERIMENTS, experiment})
  } catch(error) {
    yield onError(error)
  }
}

function* deleteTextEntry(action) {
  try {
    const {params} = action;
    yield call(deleteTextEntryApi, params);
  } catch (error) {
    yield onError(error);
  }
}

function* deleteBigAssumption(action) {
  try {
    const {params} = action;

    const bigAssumptions = yield call(deleteBigAssumptionApi, params);

    yield put({type: UPDATE_BIG_ASSUMPTIONS, bigAssumptions});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteGoal(action) {
  try {
    const {params} = action;

    const goal = yield call(deleteGoalApi, params);

    yield put({type: SET_EMPTY_GOAL, goal});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteBusinessGoal(action) {
  try {
    const {params} = action;

    const goal = yield call(deleteBusinessGoalApi, params);

    yield put({type: SET_EMPTY_BUSINESS_GOAL, goal});
  } catch (error) {
    yield onError(error);
  }
}

function* deleteExperiment(action) {
  try {
    const {params, params: {id}} = action;
    const experiment = yield call(deleteExperimentApi, params);

    yield put({type: SET_UPDATED_EXPERIMENT, experiment, id});
  } catch(error) {
    yield onError(error);
  }
}

function* updateBigAssumptionsOrder(action) {
  try {
    const {params} = action;

    const bigAssumptions = yield call(
      updateBigAssumptionsOrderInGoalApi,
      params,
    );

    yield put({type: UPDATE_BIG_ASSUMPTIONS, bigAssumptions});
  } catch (error) {
    yield onError(error);
  }
}

function* updateTextEntriesOrder(action) {
  try {
    const {params} = action;
    const newEntries = yield call(updateTextEntriesOrderInGoalApi, params);

    yield put({type: UPDATE_TEXT_ENTRY, value: {[params.field]: newEntries}});
  } catch (error) {
    yield onError(error);
  }
}

function* updateExperimentImpact(action) {
  try {
    const {params} = action;
    const bigAssumption = yield call(updateExperimentImpactApi, params);

    yield put({type: SET_EXPERIMENTS, bigAssumption});
  } catch (error) {
    yield onError(error);
  }
}

function* updateExperimentsOrder(action) {
  try {
    const {params} = action;

    const bigAssumption = yield call(updateExperimentsOrderApi, params);

    yield put({type: SET_EXPERIMENTS, bigAssumption});
  } catch (error) {
    yield onError(error);
  }
}

function* updateTakingStockQuestion(action) {
  try {
    const {params} = action;
    const newQuestionValue = yield call(updateTakingStockQuestionApi, params);

    yield put({
      type: SET_QUESTION_FOR_TAKING_STOCK,
      value: {[params.value.field]: newQuestionValue},
    });
  } catch (error) {
    yield onError(error);
  }
}

function* updateTakingStockRangeQuestion(action) {
  try {
    const {params} = action;
    const newQuestionValue = yield call(
      updateTakingStockRangeQuestionApi,
      params,
    );

    yield put({
      type: SET_QUESTION_FOR_TAKING_STOCK,
      value: {[params.field]: newQuestionValue},
    });
  } catch (error) {
    yield onError(error);
  }
}

function* deleteTakingStockQuestionEntry(action) {
  try {
    const {params} = action;
    const newQuestionValue = yield call(
      deleteTakingStockQuestionEntryApi,
      params,
    );

    yield put({
      type: SET_QUESTION_FOR_TAKING_STOCK,
      value: {[params.value.field]: newQuestionValue},
    });
  } catch (error) {
    yield onError(error);
  }
}

function* toggleBigAssumption(action) {
  try {
    const {params} = action;
    const isActive = yield call(toggleBigAssumptionApi, params);

    yield put({
      type: UPDATE_BIG_ASSUMPTION,
      assumptionId: params.assumptionId,
      field: 'isActive',
      value: isActive,
    });
  } catch (error) {
    yield onError(error);
  }
}

function* toggleExperiment(action) {
  try {
    const {params} = action;
    const {isHidden} = yield call(toggleExperimentApi, params);
    
    yield put({
      type: UPDATE_EXPERIMENT,
      experimentId: params._id,
      bigAssumptionId: params.bigAssumptionId,
      field: 'isHidden',
      value: isHidden
    })
  } catch(error) {
    yield onError(error)
  }
}

function* toggleBusinessGoal(action) {
  try {
    const {params} = action;
    const {isBGCollapsed} = yield call (toggleBusinessGoalApi, params);

    yield put({
      type: SET_BUSINESS_GOAL_COLLAPSED,
      isBGCollapsed
    })
  } catch (error) {
    yield onError(error);
  }
}

export function* getSprintWatcher() {
  yield takeLatest(GET_USER_SPRINT, getSprint);
}

export function* getUserSprintsWatcher() {
  yield takeLatest(GET_USER_SPRINTS, getSprints);
}

export function* requestMapsWatcher() {
  yield takeLatest(REQUEST_MAPS, requestMaps);
}

export function* createMapsWatcher() {
  yield takeLatest(CREATE_MAP, createMap);
}

export function* updateSprintWatcher() {
  yield takeLatest(UPDATE_SPRINT, updateSprint)
}

export function* saveGoalWatcher() {
  yield takeLatest(SAVE_GOAL, saveGoal);
}

export function* deleteGoalWatcher() {
  yield takeLatest(DELETE_GOAL, deleteGoal);
}

export function* deleteBusinessGoalWatcher() {
  yield takeLatest(DELETE_BUSINESS_GOAL, deleteBusinessGoal);
}

export function* updateGoalNoteWatcher() {
  yield takeLatest(UPDATE_GOAL_NOTE, updateGoalNote);
}

export function* createGoalNoteWatcher() {
  yield takeLatest(CREATE_GOAL_NOTE, createGoalNotes);
}

export function* deleteGoalNoteWatcher() {
  yield takeLatest(DELETE_GOAL_NOTE, deleteGoalNotes);
}

export function* updateGoalNotesOrderWatcher() {
  yield takeLatest(UPDATE_GOAL_NOTES_ORDER, updateNotesOrder);
}

export function* saveTextEntryWatcher() {
  yield takeLatest(SAVE_TEXT_ENTRY, saveTextEntry);
}

export function* saveBigAssumptionWatcher() {
  yield takeLatest(SAVE_BIG_ASSUMPTION, saveBigAssumption);
}

export function* createNewExperimentWatcher() {
  yield takeLatest(CREATE_EXPERIMENT, createNewExperiments);
}

export function* deleteTextEntryWatcher() {
  yield takeLatest(DELETE_TEXT_ENTRY, deleteTextEntry);
}

export function* deleteBigAssumptionWatcher() {
  yield takeLatest(DELETE_BIG_ASSUMPTION, deleteBigAssumption);
}

export function* deleteExperimentWatcher() {
  yield takeLatest(DELETE_EXPERIMENT, deleteExperiment);
}

export function* saveExperimentEntryWatcher() {
  yield takeLatest(SAVE_EXPERIMENT_ENTRY, saveExperimentEntry);
}

export function* updateExperimentEntryWatcher() {
  yield takeLatest(UPDATE_EXPERIMENT_ENTRY, updateExperimentEntry);
}

export function* updateBigAssumptionsOrderWatcher() {
  yield takeLatest(UPDATE_BIG_ASSUMPTIONS_ORDER, updateBigAssumptionsOrder);
}

export function* updateTextEntriesOrderWatcher() {
  yield takeLatest(UPDATE_TEXT_ENTRIES_ORDER, updateTextEntriesOrder);
}

export function* updateExperimentsOrderWatcher() {
  yield takeLatest(UPDATE_EXPERIMENTS_ORDER, updateExperimentsOrder);
}

export function* updateTakingStockQuestionWatcher() {
  yield takeLatest(UPDATE_TAKING_STOCK_QUESTION, updateTakingStockQuestion);
}

export function* updateExperimentImpactWatcher() {
  yield takeLatest(SET_EXPERIMENT_IMPACT, updateExperimentImpact);
}

export function* deleteTakingStockQuestionEntryWatcher() {
  yield takeLatest(
    DELETE_TAKING_STOCK_QUESTION_ENTRY,
    deleteTakingStockQuestionEntry,
  );
}

export function* updateTakingStockRangeQuestionWatcher() {
  yield takeLatest(
    UPDATE_TAKING_STOCK_RANGE_QUESTION,
    updateTakingStockRangeQuestion,
  );
}

export function* toggleBigAssumptionWatcher() {
  yield takeLatest(TOGGLE_BIG_ASSUMPTION, toggleBigAssumption);
}

export function* toggleExperimentWatcher() {
  yield takeLatest(TOGGLE_EXPERIMENT, toggleExperiment)
}

export function* toggleBusinessGoalWatcher() {
  yield takeLatest(TOGGLE_BUSINESS_GOAL, toggleBusinessGoal)
}

import {createReducer} from '../utils';
import {
  SET_USER_SPRINT,
  SET_USER_TEAM_SPRINT,
  UPDATE_TEXT_ENTRY,
  SET_EXPERIMENTS,
  UPDATE_BIG_ASSUMPTIONS,
  UPDATE_BIG_ASSUMPTION,
  SET_QUESTION_FOR_TAKING_STOCK,
  GET_USER_SPRINT,
  ON_API_ERROR,
  SET_BUSINESS_GOAL_COLLAPSED,
  SET_GOAL_NOTES,
  UPDATE_EXPERIMENTS,
  UPDATE_EXPERIMENT,
  SET_UPDATED_EXPERIMENT,
  SET_EMPTY_GOAL,
  SET_EMPTY_BUSINESS_GOAL,
} from '../actions/types';

const initialState = null;

const sprintReducer = createReducer(initialState, {
  [GET_USER_SPRINT]: () => ({loading: true}),
  [SET_USER_SPRINT]: (state, {sprint}) => {
    return {
      ...sprint,
    };
  },
  [SET_USER_TEAM_SPRINT]: (state, {teamSprint}) => {
    return {
      ...teamSprint,
    };
  },
  [UPDATE_TEXT_ENTRY]: (state, newEntries) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        ...newEntries.value,
      },
    };
  },
  [UPDATE_BIG_ASSUMPTIONS]: (state, {bigAssumptions}) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions,
      },
    };
  },
  [UPDATE_EXPERIMENT]: (state, {bigAssumptionId, experimentId, value}) => {
    const {
      goal: {bigAssumptions}
    } = state;
    
    bigAssumptions.forEach(assumption => {
      if(assumption._id === bigAssumptionId) {
        assumption.experiments.forEach(experiment => {
          if(experiment._id === experimentId) {
            experiment.isHidden = value;
          }
        })
      }
    });

    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions: [...bigAssumptions],
      }
    }
  },
  [UPDATE_BIG_ASSUMPTION]: (state, {assumptionId, field, value}) => {
    const {
      goal: {bigAssumptions},
    } = state;
    const assumptionToUpdate = bigAssumptions.find(
      (assumption) => assumption._id === assumptionId,
    );

    if (assumptionToUpdate) {
      assumptionToUpdate[field] = value;
    }

    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions: [...bigAssumptions],
      },
    };
  },
  [SET_EXPERIMENTS]: (state, {bigAssumption}) => {
    let oldBigAssumptionIndex;
    if (!state) {
      return state;
    }
    state.goal.bigAssumptions.forEach((bigAss, index) => {
      if (bigAss._id === bigAssumption._id) {
        oldBigAssumptionIndex = index;
      }
    });
    const newAssumptions = [...state.goal.bigAssumptions];
    newAssumptions[oldBigAssumptionIndex] = {...bigAssumption};

    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions: [...newAssumptions],
      },
    };
  },
  [SET_QUESTION_FOR_TAKING_STOCK]: (state, newQuestionValue) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        takingStock: {
          ...state.goal.takingStock,
          ...newQuestionValue.value,
        },
      },
    };
  },
  [ON_API_ERROR]: () => initialState,
  [SET_GOAL_NOTES]: (state, {notes}) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        notes,
      }
    }
  },
  [SET_BUSINESS_GOAL_COLLAPSED]: (state, {isBGCollapsed}) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        isBGCollapsed
      }
    }
  },
  [UPDATE_EXPERIMENTS]: (state, {experiment}) => {
    const {bigAssumption} = experiment;

    const updatedBigAssumptions = state.goal.bigAssumptions.map((assumption) => {
      if (assumption._id === bigAssumption) {
        assumption.experiments = [...assumption.experiments, experiment];
      }
      return assumption
    });
    
    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions: updatedBigAssumptions
      }
    }
  },
  [SET_UPDATED_EXPERIMENT]: (state, {experiment, id}) => {
    const {goal: {bigAssumptions}} = state;
    const assumptions = [...bigAssumptions];

    const updatedBigAssumptions = assumptions.map((assumption) => {
      if (assumption._id === id) {
        assumption.experiments.map((exp) => {
          if(exp._id === experiment._id) {
            exp.isDeleted = experiment.isDeleted;
            exp.isHidden = experiment.isHidden;
          }
          return exp;
        })
      }
      return assumption
    });

    return {
      ...state,
      goal: {
        ...state.goal,
        bigAssumptions: updatedBigAssumptions
      }
    }
  },
  [SET_EMPTY_GOAL]: (state, {label}) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        label
      }
    }
  },
  [SET_EMPTY_BUSINESS_GOAL]: (state, {businessGoal}) => {
    return {
      ...state,
      goal: {
        ...state.goal,
        businessGoal
      }
    }
  },
});

export default sprintReducer;
